import {
  ToastDuration,
  ToastType,
  useToast,
} from '@leland-dev/leland-ui-library';
import { type DocumentNode } from 'graphql';
import { type FC, type MouseEvent, useCallback } from 'react';

import mxpnl from '../../utils/analytics/mixpanel';
import { wasMutationSuccessful } from '../../utils/mutations';
import { getClassUrl } from '../../utils/routing';
import { BaseEventCard } from '../common/BaseEventCard';
import { usePhoneNumberModal } from '../sms/PhoneNumberModal';

import {
  type ClassCardClassFragment,
  type ClassCardClassSessionFragment,
  useClassCardEnrollFreeEventMutation,
  useClassCardUnenrollFreeEventMutation,
  useClassCardUserQuery,
} from './__generated-gql-types__/ClassCard.generated';

interface ClassCardProps {
  classInfo: ClassCardClassFragment;
  classSession?: ClassCardClassSessionFragment;
  className?: string;
  onClick?: () => void;
  refetchQueries?: DocumentNode[];
  trackingPrefix: string;
}

const ClassCard: FC<ClassCardProps> = ({
  classInfo,
  classSession,
  onClick,
  refetchQueries,
  trackingPrefix,
}) => {
  const classPagePathname = getClassUrl(
    classInfo.slug ?? '',
    !!classInfo.isFreeEvent,
  );
  const isUserEnrolledInCurrentSession = classSession?.userIsEnrolled;
  const isUserBlocked = !!classInfo.coach?.coachingRelationship?.blockedByCoach;
  const [enrollFreeEventAction] = useClassCardEnrollFreeEventMutation();
  const [unenrollFreeEventAction] = useClassCardUnenrollFreeEventMutation();
  const { data: userData } = useClassCardUserQuery();
  const { showToast } = useToast();
  const {
    openModalIfNecessary: openPhoneNumberModalIfNecessary,
    Modal: PhoneNumberModal,
  } = usePhoneNumberModal();

  const handleButtonClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (userData?.user) {
        if (classInfo.isFreeEvent && classSession) {
          e.preventDefault();
          if (isUserEnrolledInCurrentSession) {
            const res = await unenrollFreeEventAction({
              variables: {
                id: classSession.id,
              },
              refetchQueries,
            });
            if (wasMutationSuccessful(res)) {
              mxpnl.track('Unenroll Free Event', {
                eventId: classInfo.classId,
                eventName: classInfo.className,
                sessionId: classSession.id,
                location: `${trackingPrefix} - ClassCard`,
              });
              showToast({
                type: ToastType.SUCCESS,
                duration: ToastDuration.NORMAL,
                message: 'You have successfully unenrolled.',
              });
            } else {
              showToast({
                type: ToastType.ERROR,
                duration: ToastDuration.NORMAL,
                message: 'Error unenrolling from session. Please try again.',
              });
            }
          } else {
            const res = await enrollFreeEventAction({
              variables: {
                id: classSession.id,
              },
              refetchQueries,
            });
            if (wasMutationSuccessful(res)) {
              mxpnl.track('Enroll Free Event', {
                eventId: classInfo.classId,
                eventName: classInfo.className,
                sessionId: classSession.id,
                location: `${trackingPrefix} - ClassCard`,
              });
              void openPhoneNumberModalIfNecessary();
              showToast({
                type: ToastType.SUCCESS,
                duration: ToastDuration.NORMAL,
                message: 'You have successfully enrolled.',
              });
            } else {
              showToast({
                type: ToastType.ERROR,
                duration: ToastDuration.NORMAL,
                message: 'Error enrolling in event. Please try again.',
              });
            }
          }
        }
      } else {
        return;
      }
      onClick?.();
    },
    [
      classInfo.classId,
      classInfo.className,
      classInfo.isFreeEvent,
      classSession,
      enrollFreeEventAction,
      isUserEnrolledInCurrentSession,
      onClick,
      refetchQueries,
      trackingPrefix,
      unenrollFreeEventAction,
      userData?.user,
      showToast,
      openPhoneNumberModalIfNecessary,
    ],
  );

  return isUserBlocked ? null : (
    <>
      <BaseEventCard
        name={classInfo.className}
        date={classSession?.startAt}
        image={classInfo.imageLink}
        coaches={classInfo.coach}
        link={classPagePathname}
        isFree={classInfo.isFreeEvent ?? false}
        categories={classInfo.category ?? []}
        isEnrolled={!!isUserEnrolledInCurrentSession}
        price={classInfo.price}
        totalAttendees={classSession?.totalAttendees}
        attendeeFacePiles={classSession?.topFacePiles?.map((picture) => ({
          pictureLink: picture,
        }))}
        onButtonClick={handleButtonClick}
      />
      <PhoneNumberModal />
    </>
  );
};

export default ClassCard;
