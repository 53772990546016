// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import {
  BaseEventCardCoachFragmentDoc,
  BaseEventCardCategoryFragmentDoc,
} from './BaseEventCard.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClassCardClassFragment = {
  __typename?: 'Class';
  imageLink?: string | null;
  slug?: string | null;
  price?: number | null;
  durationInMinute?: number | null;
  maximumAttendees?: number | null;
  isUserOnDiscoveryList?: boolean | null;
  isFreeEvent?: boolean | null;
  classId: string;
  className?: string | null;
  category?: { __typename?: 'Category'; id: string; slug: string } | null;
  coach?: {
    __typename?: 'Coach';
    id: string;
    slug: string;
    averageReviewRate: number;
    reviewTotal: number;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
    coachingRelationship?: {
      __typename?: 'CoachingRelationship';
      id: string;
      blockedByCoach: boolean;
    } | null;
    categoryInformationList?: Array<{
      __typename?: 'CategoryInformation';
      category: { __typename?: 'Category'; id: string; slug: string };
    }> | null;
  } | null;
};

export type ClassCardClassSessionFragment = {
  __typename?: 'CoachingSession';
  id: string;
  startAt: number;
  totalAttendees: number;
  userIsEnrolled?: boolean | null;
  topFacePiles: Array<string>;
};

export type ClassCardJoinDiscoveryListMutationVariables = Types.Exact<{
  id: Types.Scalars['ClassUrn']['input'];
}>;

export type ClassCardJoinDiscoveryListMutation = {
  __typename?: 'Mutation';
  joinDiscoveryList: boolean;
};

export type ClassCardLeaveDiscoveryListMutationVariables = Types.Exact<{
  id: Types.Scalars['ClassUrn']['input'];
}>;

export type ClassCardLeaveDiscoveryListMutation = {
  __typename?: 'Mutation';
  leaveDiscoveryList: boolean;
};

export type ClassCardEnrollFreeEventMutationVariables = Types.Exact<{
  id: Types.Scalars['CoachingSessionUrn']['input'];
}>;

export type ClassCardEnrollFreeEventMutation = {
  __typename?: 'Mutation';
  enrollFreeEvent: {
    __typename?: 'CoachingSession';
    id: string;
    userIsEnrolled?: boolean | null;
  };
};

export type ClassCardUnenrollFreeEventMutationVariables = Types.Exact<{
  id: Types.Scalars['CoachingSessionUrn']['input'];
}>;

export type ClassCardUnenrollFreeEventMutation = {
  __typename?: 'Mutation';
  unenrollFreeEvent: {
    __typename?: 'CoachingSession';
    id: string;
    userIsEnrolled?: boolean | null;
  };
};

export type ClassCardUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClassCardUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    applicant?: { __typename?: 'Applicant'; id: string } | null;
  } | null;
};

export const ClassCardClassFragmentDoc = gql`
  fragment ClassCard_Class on Class {
    classId: id
    category {
      id
      slug
    }
    className: name
    imageLink
    slug
    price
    durationInMinute
    maximumAttendees
    coach {
      id
      slug
      user {
        firstName
        lastName
      }
      coachingRelationship {
        id
        blockedByCoach
      }
      averageReviewRate
      reviewTotal
      ...BaseEventCard_Coach
    }
    isUserOnDiscoveryList
    isFreeEvent
  }
  ${BaseEventCardCoachFragmentDoc}
`;
export const ClassCardClassSessionFragmentDoc = gql`
  fragment ClassCard_ClassSession on CoachingSession {
    id
    startAt
    totalAttendees
    userIsEnrolled
    topFacePiles
  }
`;
export const ClassCardJoinDiscoveryListDocument = gql`
  mutation ClassCard_JoinDiscoveryList($id: ClassUrn!) {
    joinDiscoveryList(id: $id)
  }
`;
export type ClassCardJoinDiscoveryListMutationFn = Apollo.MutationFunction<
  ClassCardJoinDiscoveryListMutation,
  ClassCardJoinDiscoveryListMutationVariables
>;

/**
 * __useClassCardJoinDiscoveryListMutation__
 *
 * To run a mutation, you first call `useClassCardJoinDiscoveryListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassCardJoinDiscoveryListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classCardJoinDiscoveryListMutation, { data, loading, error }] = useClassCardJoinDiscoveryListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassCardJoinDiscoveryListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClassCardJoinDiscoveryListMutation,
    ClassCardJoinDiscoveryListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClassCardJoinDiscoveryListMutation,
    ClassCardJoinDiscoveryListMutationVariables
  >(ClassCardJoinDiscoveryListDocument, options);
}
export type ClassCardJoinDiscoveryListMutationHookResult = ReturnType<
  typeof useClassCardJoinDiscoveryListMutation
>;
export type ClassCardJoinDiscoveryListMutationResult =
  Apollo.MutationResult<ClassCardJoinDiscoveryListMutation>;
export type ClassCardJoinDiscoveryListMutationOptions =
  Apollo.BaseMutationOptions<
    ClassCardJoinDiscoveryListMutation,
    ClassCardJoinDiscoveryListMutationVariables
  >;
export const ClassCardLeaveDiscoveryListDocument = gql`
  mutation ClassCard_LeaveDiscoveryList($id: ClassUrn!) {
    leaveDiscoveryList(id: $id)
  }
`;
export type ClassCardLeaveDiscoveryListMutationFn = Apollo.MutationFunction<
  ClassCardLeaveDiscoveryListMutation,
  ClassCardLeaveDiscoveryListMutationVariables
>;

/**
 * __useClassCardLeaveDiscoveryListMutation__
 *
 * To run a mutation, you first call `useClassCardLeaveDiscoveryListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassCardLeaveDiscoveryListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classCardLeaveDiscoveryListMutation, { data, loading, error }] = useClassCardLeaveDiscoveryListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassCardLeaveDiscoveryListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClassCardLeaveDiscoveryListMutation,
    ClassCardLeaveDiscoveryListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClassCardLeaveDiscoveryListMutation,
    ClassCardLeaveDiscoveryListMutationVariables
  >(ClassCardLeaveDiscoveryListDocument, options);
}
export type ClassCardLeaveDiscoveryListMutationHookResult = ReturnType<
  typeof useClassCardLeaveDiscoveryListMutation
>;
export type ClassCardLeaveDiscoveryListMutationResult =
  Apollo.MutationResult<ClassCardLeaveDiscoveryListMutation>;
export type ClassCardLeaveDiscoveryListMutationOptions =
  Apollo.BaseMutationOptions<
    ClassCardLeaveDiscoveryListMutation,
    ClassCardLeaveDiscoveryListMutationVariables
  >;
export const ClassCardEnrollFreeEventDocument = gql`
  mutation ClassCard_EnrollFreeEvent($id: CoachingSessionUrn!) {
    enrollFreeEvent(id: $id) {
      id
      userIsEnrolled
    }
  }
`;
export type ClassCardEnrollFreeEventMutationFn = Apollo.MutationFunction<
  ClassCardEnrollFreeEventMutation,
  ClassCardEnrollFreeEventMutationVariables
>;

/**
 * __useClassCardEnrollFreeEventMutation__
 *
 * To run a mutation, you first call `useClassCardEnrollFreeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassCardEnrollFreeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classCardEnrollFreeEventMutation, { data, loading, error }] = useClassCardEnrollFreeEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassCardEnrollFreeEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClassCardEnrollFreeEventMutation,
    ClassCardEnrollFreeEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClassCardEnrollFreeEventMutation,
    ClassCardEnrollFreeEventMutationVariables
  >(ClassCardEnrollFreeEventDocument, options);
}
export type ClassCardEnrollFreeEventMutationHookResult = ReturnType<
  typeof useClassCardEnrollFreeEventMutation
>;
export type ClassCardEnrollFreeEventMutationResult =
  Apollo.MutationResult<ClassCardEnrollFreeEventMutation>;
export type ClassCardEnrollFreeEventMutationOptions =
  Apollo.BaseMutationOptions<
    ClassCardEnrollFreeEventMutation,
    ClassCardEnrollFreeEventMutationVariables
  >;
export const ClassCardUnenrollFreeEventDocument = gql`
  mutation ClassCard_UnenrollFreeEvent($id: CoachingSessionUrn!) {
    unenrollFreeEvent(id: $id) {
      id
      userIsEnrolled
    }
  }
`;
export type ClassCardUnenrollFreeEventMutationFn = Apollo.MutationFunction<
  ClassCardUnenrollFreeEventMutation,
  ClassCardUnenrollFreeEventMutationVariables
>;

/**
 * __useClassCardUnenrollFreeEventMutation__
 *
 * To run a mutation, you first call `useClassCardUnenrollFreeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassCardUnenrollFreeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classCardUnenrollFreeEventMutation, { data, loading, error }] = useClassCardUnenrollFreeEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassCardUnenrollFreeEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClassCardUnenrollFreeEventMutation,
    ClassCardUnenrollFreeEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClassCardUnenrollFreeEventMutation,
    ClassCardUnenrollFreeEventMutationVariables
  >(ClassCardUnenrollFreeEventDocument, options);
}
export type ClassCardUnenrollFreeEventMutationHookResult = ReturnType<
  typeof useClassCardUnenrollFreeEventMutation
>;
export type ClassCardUnenrollFreeEventMutationResult =
  Apollo.MutationResult<ClassCardUnenrollFreeEventMutation>;
export type ClassCardUnenrollFreeEventMutationOptions =
  Apollo.BaseMutationOptions<
    ClassCardUnenrollFreeEventMutation,
    ClassCardUnenrollFreeEventMutationVariables
  >;
export const ClassCardUserDocument = gql`
  query ClassCard_User {
    user {
      id
      applicant {
        id
      }
    }
  }
`;

/**
 * __useClassCardUserQuery__
 *
 * To run a query within a React component, call `useClassCardUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassCardUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassCardUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassCardUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClassCardUserQuery,
    ClassCardUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClassCardUserQuery, ClassCardUserQueryVariables>(
    ClassCardUserDocument,
    options,
  );
}
export function useClassCardUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassCardUserQuery,
    ClassCardUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClassCardUserQuery, ClassCardUserQueryVariables>(
    ClassCardUserDocument,
    options,
  );
}
export function useClassCardUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClassCardUserQuery,
    ClassCardUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClassCardUserQuery,
    ClassCardUserQueryVariables
  >(ClassCardUserDocument, options);
}
export type ClassCardUserQueryHookResult = ReturnType<
  typeof useClassCardUserQuery
>;
export type ClassCardUserLazyQueryHookResult = ReturnType<
  typeof useClassCardUserLazyQuery
>;
export type ClassCardUserSuspenseQueryHookResult = ReturnType<
  typeof useClassCardUserSuspenseQuery
>;
export type ClassCardUserQueryResult = Apollo.QueryResult<
  ClassCardUserQuery,
  ClassCardUserQueryVariables
>;
