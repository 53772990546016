import {
  Button,
  ButtonColor,
  ButtonWidth,
  compact,
  DateFormat,
  formatDate,
  formatName,
  formatTime,
  IconCheck,
  IconLinkExternal,
  isDateBefore,
  Popover,
  ProfileImageStack,
  Rating,
  RatingSize,
} from '@leland-dev/leland-ui-library';
import Image, { type StaticImageData } from 'next/image';
import Link from 'next/link';
import { type FC, type MouseEventHandler } from 'react';

import EventPlaceholderImage from '../../assets/images/events/event-placeholder.png';
import { SUBSCRIBER_DUMMY_COACH_URN } from '../../utils/constants';
import { getCategoryForEvent } from '../../utils/events';
import { formatPrice } from '../../utils/formatters';
import { getCoachProfileUrl } from '../../utils/routing';

import {
  type BaseEventCardCategoryFragment,
  type BaseEventCardCoachFragment,
} from './__generated-gql-types__/BaseEventCard.generated';

interface FacePileProps {
  pictureLink?: string;
  name?: string;
}

export interface BaseEventCardProps {
  date: Possible<number>;
  name: Possible<string>;
  image: Possible<string | StaticImageData>;
  type?: Possible<string>;
  isFree?: boolean;
  coaches: Possible<BaseEventCardCoachFragment[] | BaseEventCardCoachFragment>;
  categories: Possible<
    BaseEventCardCategoryFragment[] | BaseEventCardCategoryFragment
  >;
  link: string;
  isEnrolled?: boolean;
  price?: Possible<number>;
  totalAttendees?: Possible<number>;
  attendeeFacePiles?: Possible<FacePileProps[]>;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
}

export const BaseEventCard: FC<BaseEventCardProps> = ({
  categories,
  type,
  coaches,
  date,
  image,
  link,
  isEnrolled,
  isFree,
  name,
  price,
  totalAttendees,
  attendeeFacePiles,
  onButtonClick,
}) => {
  const isPastEvent = date && isDateBefore(date, Date.now());

  const filteredCoaches = (Array.isArray(coaches) ? coaches : [coaches])
    .filter(compact)
    .filter((c) => c.id != SUBSCRIBER_DUMMY_COACH_URN);

  const numCoachesToDisplayText = filteredCoaches.length > 2 ? 1 : 2;
  const coachesToDisplayText = filteredCoaches.slice(
    0,
    numCoachesToDisplayText,
  );
  const moreCoachesText = filteredCoaches.slice(numCoachesToDisplayText);

  const numCoachesToDisplayStack = filteredCoaches.length === 3 ? 3 : 2;
  const coachesForStack = filteredCoaches
    .slice(0, numCoachesToDisplayStack)
    .map((coach) => coach.user);
  const moreCoachesForStack = filteredCoaches.slice(numCoachesToDisplayStack);

  const isExternal = type === 'Shindig' ? true : null;

  const coachToUseForReview =
    filteredCoaches.length > 0 ? filteredCoaches[0] : null;

  return (
    <Link
      className="w-full self-stretch"
      href={link}
      target={isExternal ? '_blank' : undefined}
    >
      <div className="flex size-full flex-col">
        <div className="relative">
          <Image
            className="aspect-entity-image w-full rounded object-cover"
            src={image ?? EventPlaceholderImage}
            alt="Event cover"
            width={400}
            height={210}
          />
          {isPastEvent ? null : isFree ? (
            <span className="absolute right-2 top-2 rounded-full bg-white px-2 py-0.5 font-medium">
              Free event
            </span>
          ) : (
            <span className="absolute right-2 top-2 rounded-full bg-white px-2 py-0.5 font-medium">
              Class
            </span>
          )}
        </div>
        {name ? (
          <h2 className="mt-4 line-clamp-1 text-xl font-medium">{name}</h2>
        ) : null}
        {!filteredCoaches.length ? null : (
          <div className="mt-1 flex w-full">
            <div className="mr-2 flex items-center justify-center">
              <ProfileImageStack profiles={coachesForStack} />
              {moreCoachesForStack.length > 0 ? (
                <Popover
                  content={moreCoachesForStack.map(({ user }, idx) => (
                    <p key={idx}>
                      {formatName({
                        firstName: user.firstName,
                        lastName: user.lastName,
                      })}
                    </p>
                  ))}
                >
                  <div
                    className={`relative -ml-1 flex size-5 shrink-0 items-center justify-center rounded-full bg-leland-gray-dark outline outline-1 outline-white`}
                  >
                    <p
                      className={`mb-0.5 mr-0.5 font-medium text-white ${
                        moreCoachesForStack.length > 9 ? 'text-xs' : 'text-sm'
                      }`}
                    >
                      +{moreCoachesForStack.length}
                    </p>
                  </div>
                </Popover>
              ) : null}
            </div>
            <div className="flex">
              {coachesToDisplayText.length ? (
                <p className="grow text-lg font-normal text-leland-gray-dark">
                  Featuring{' '}
                  {coachesToDisplayText.map((coach, i) =>
                    coach ? (
                      <span key={coach.id}>
                        {coachesToDisplayText.length > 1 &&
                        i === coachesToDisplayText.length - 1 &&
                        !moreCoachesText.length
                          ? 'and '
                          : ''}
                        <a
                          href={getCoachProfileUrl(
                            coach.slug,
                            getCategoryForEvent(
                              coach.categoryInformationList?.map(
                                (catInfo) => catInfo.category.slug,
                              ) ?? [],
                              Array.isArray(categories)
                                ? categories.map((cat) => cat.slug)
                                : categories
                                  ? [categories.slug]
                                  : [],
                            ),
                          )}
                          className="hover:underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatName({
                            firstName: coach.user.firstName,
                            lastName: coach.user.lastName,
                          })}
                        </a>
                        {i === coachesToDisplayText.length - 1
                          ? ''
                          : coachesToDisplayText.length > 2
                            ? ', '
                            : ' '}
                      </span>
                    ) : null,
                  )}
                  {moreCoachesText.length > 0 ? (
                    <>
                      {' '}
                      +{' '}
                      <Popover
                        content={moreCoachesText.map(({ user }, idx) => (
                          <p key={idx}>
                            {formatName({
                              firstName: user.firstName,
                              lastName: user.lastName,
                            })}
                          </p>
                        ))}
                      >
                        {moreCoachesText.length} more
                      </Popover>
                    </>
                  ) : null}
                </p>
              ) : null}
              {coachToUseForReview?.averageReviewRate ? (
                <div className="ml-3 flex items-center justify-start">
                  <Rating
                    rate={coachToUseForReview.averageReviewRate}
                    showRate
                    totalStars={1}
                    size={RatingSize.MEDIUM}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
        {date ? (
          <div className="mt-1 flex items-center justify-start gap-2 text-lg font-normal leading-none">
            <p className="text-leland-gray-light">
              {formatDate(date, DateFormat.SHORT_MONTH_DAY)} @{' '}
              {formatTime(date)}
            </p>
            {price ? (
              <p className="text-leland-primary">
                {formatPrice({
                  amount: price,
                })}
              </p>
            ) : null}
          </div>
        ) : null}

        {attendeeFacePiles?.length ? (
          <div className="mt-3 flex">
            <ProfileImageStack
              profiles={attendeeFacePiles.map((attendee) => ({
                firstName: attendee.name?.split(' ')[0],
                lastName: attendee.name?.split(' ')[1],
                pictureLink: attendee.pictureLink,
              }))}
            />
            <p className="ml-1.5 text-lg text-leland-gray-extra-light">
              {totalAttendees} {totalAttendees == 1 ? 'person' : 'people'} going
            </p>
          </div>
        ) : null}

        {isPastEvent ? null : (
          <a
            className="mt-3 flex w-fit items-end"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              label={
                isEnrolled
                  ? 'You are registered'
                  : isFree
                    ? 'Register for free'
                    : 'Register'
              }
              LeftIcon={isEnrolled ? IconCheck : undefined}
              RightIcon={isExternal ? IconLinkExternal : undefined}
              buttonColor={isEnrolled ? ButtonColor.GRAY : ButtonColor.BLACK}
              width={ButtonWidth.FULL}
              onClick={onButtonClick}
            />
          </a>
        )}
      </div>
    </Link>
  );
};
